import {
    action,
    computed,
    extendObservable,
    makeAutoObservable,
    observable,
    runInAction,
} from 'mobx'
import * as Type from '../extras/pokemonTeamBuilder/TeamBuilderTypes'
import * as Utils from '../extras/pokemonTeamBuilder/TeamBuilderUtils'
import { getLanguages } from './helpers/languages'
import request, { gql } from 'graphql-request'
import { getPokedexes } from './helpers/pokedexes'

const { Pokedex } = require('pokeapi-js-wrapper')
const Dex = new Pokedex(Type.customOptions)

export class DataStore {
    TeamBuilderData: Map<string, Record<string, unknown>> = new Map<
        string,
        Record<string, unknown>
    >([])

    loading = true
    languagesLoaded = false
    pokedexesLoaded = false
    pokemonDexURLsLoaded = false
    pokemonLoaded = false
    naturesLoaded = false
    movesLoaded = false
    itemsLoaded = false
    abilitiesLoaded = false

    currentLanguage: Type.Languages = Type.Languages.en

    Languages: Type.LanguagesGQL = { languages: [] }
    AllItems: Type.Item[] = []
    AllItemsMap: Map<string, Type.Item> = new Map()
    AllNatures: Type.NatureInfo[] = []
    AllNaturesMap: Map<string, Type.NatureInfo> = new Map()
    AllAbilities: Type.Ability[] = []
    AllAbilitiesMap: Map<string, Type.Ability> = new Map()
    AllMoves: Type.Move[] = []
    AllMovesMap: Map<string, Type.Move> = new Map()
    PokemonDexURLs: Type.NamedUrl[] = []

    PokemonGameData: Type.PokemonGameData[] = []
    PokemonGameDataMap: Map<string, Type.PokemonGameData> = new Map()

    currentRegionalDex: string = 'national'
    regionalDexAvailablePokemon: Type.PokemonDexData[] = []

    Pokedexes: Type.PokedexesGQL = { pokedexes: [] }

    indexOfLastPokemonUpdated: Type.teamIndexRange = 0
    sectionChangedDuringLastUpdate: Type.UpdatableSection | undefined =
        undefined
    subsectionChangedDuringLastUpdate: Type.UpdatableSubSection | undefined =
        undefined
    team = [...Array<Type.Pokemon | undefined>(6)]

    constructor() {
        makeAutoObservable(this, {
            loading: observable,
            languagesLoaded: false,
            pokedexesLoaded: false,
            pokemonDexURLsLoaded: false,
            pokemonLoaded: false,
            naturesLoaded: false,
            movesLoaded: false,
            itemsLoaded: false,
            abilitiesLoaded: false,
            currentLanguage: observable,
            Languages: observable,
            Pokedexes: observable,
            AllItems: observable,
            AllItemsMap: observable,
            AllNatures: observable,
            AllNaturesMap: observable,
            AllAbilities: observable,
            AllAbilitiesMap: observable,
            PokemonDexURLs: observable,
            PokemonDexData: computed,
            PokemonGameData: observable,
            PokemonGameDataMap: observable,
            currentRegionalDex: observable,
            indexOfLastPokemonUpdated: observable,
            team: observable,
            // writeToFile: action,
            printStore: action,
            setLanguage: action,
            setPokedex: action,
            setLastUpdatedIndex: action.bound,
            setLastUpdatedSection: action.bound,
            setLastUpdatedSubSection: action.bound,
            updateRegionalDexAvailablePokemon: action.bound,
            updateTeam: action.bound,
            loadData: action.bound,
        })
        this.loadData(true)
    }

    async updateRegionalDexAvailablePokemon() {
        const allPokemonDexData = await Dex.getPokedexByName(
            this.currentRegionalDex
        ).then((dexData: Type.PokedexData) => {
            return Promise.all(
                dexData.pokemon_entries.map(
                    async (entry): Promise<Type.PokemonDexData> => {
                        /* eslint no-return-await: "off" */
                        return await Dex.getPokemonSpeciesByName(
                            entry.pokemon_species.name
                        )
                    }
                )
            ).then((pokemonDexData: Type.PokemonDexData[]) => {
                return pokemonDexData
            })
        })
        runInAction(() => {
            this.regionalDexAvailablePokemon = allPokemonDexData
        })
    }

    updateTeam(
        index: Type.teamIndexRange,
        pokemon: Type.Pokemon,
        sectionUpdated?: Type.UpdatableSection,
        subsectionUpdated?: Type.UpdatableSubSection
    ) {
        this.team[index] = pokemon
        runInAction(() => {
            console.log(`Update ${index}`, JSON.parse(JSON.stringify(pokemon)))
            this.indexOfLastPokemonUpdated = index
            this.sectionChangedDuringLastUpdate = sectionUpdated
            this.subsectionChangedDuringLastUpdate = subsectionUpdated
        })
    }

    async setLanguage(lang: Type.Languages) {
        const newLanguageList = await getLanguages(lang).then(
            (newLanguageList) => {
                return newLanguageList
            }
        )
        const newPokedexList = await getPokedexes(lang)
        runInAction(() => {
            this.currentLanguage = lang
            this.Languages = newLanguageList
            this.Pokedexes = newPokedexList
        })
    }

    get PokemonDexData() {
        return this.regionalDexAvailablePokemon
    }

    setLastUpdatedIndex(index: Type.teamIndexRange) {
        this.indexOfLastPokemonUpdated = index
    }

    setLastUpdatedSection(section: Type.UpdatableSection) {
        this.sectionChangedDuringLastUpdate = section
    }

    setLastUpdatedSubSection(subsection: Type.UpdatableSubSection) {
        this.subsectionChangedDuringLastUpdate = subsection
    }

    async loadData(skip = false) {
        let loadingRegionalDex: string

        let loadingPokedexData: Type.PokedexData[]
        let loadingPokedexDataMap: Map<string, Type.PokedexData>

        let loadingPokemonDexURLs: Type.NamedUrl[]

        // let loadingPokemonGameData: Type.PokemonGameData[];
        // let loadingPokemonGameDataMap: Map<string, Type.PokemonGameData>;

        console.log('Loading data?')

        if (!skip) {
            await this.fetchLanguageData()
            await this.fetchPokedexData()
            await this.fetchPokemonDexURLs()
            await this.fetchPokemonData()
            await this.fetchNatureData()
            await this.fetchAbilityData()
            await this.fetchItemData()
            await this.fetchMoveData()
        } else {
            await this.fetchLanguageData()
            await this.fetchPokedexData()
        }

        runInAction(() => {
            this.loading = false
        })
    }

    printStore = (): void => {
        console.log(this)
    }

    getTeamBuilderData = (
        searchKey: string
    ): Record<string, unknown> | undefined => {
        return this.TeamBuilderData.get(searchKey) || undefined
    }

    setTeamBuilderData = (
        key: string,
        value: Record<string, unknown>
    ): void => {
        this.TeamBuilderData.set(key, value)
    }

    setAllTeamBuilderData = (
        data: Map<string, Record<string, unknown>>
    ): void => {
        this.TeamBuilderData = data
    }

    setPokedex = async (dex: string): Promise<void> => {
        const newPokedexList = await getPokedexes(this.currentLanguage)
        runInAction(() => {
            this.currentRegionalDex = dex
            this.Pokedexes = newPokedexList
            console.log('LOADED: Pokedexes', newPokedexList)
        })
    }

    fetchPokedexData = async (): Promise<void> => {
        const pokedexList = await getPokedexes() // Default to english, should probably implement local storage language memory
        runInAction(() => {
            this.currentRegionalDex = 'national' // loadingRegionalDex;
            this.Pokedexes = pokedexList
            this.pokedexesLoaded = true
            console.log('LOADED: Pokedexes', pokedexList)
        })
    }

    fetchPokemonDexURLs = async (): Promise<void> => {
        const loadingPokemonDexURLs = await Dex.getPokedexs().then(
            async (allPokedexs: any) => {
                return allPokedexs.results as Type.NamedUrl[]
            }
        )
        runInAction(() => {
            this.PokemonDexURLs = loadingPokemonDexURLs
            this.pokemonDexURLsLoaded = true
            console.log('LOADED: DexURLs')
        })
    }

    fetchLanguageData = async (): Promise<void> => {
        const languageList = await getLanguages() // Default to english, should probably implement local storage language memory
        runInAction(() => {
            this.Languages = languageList
            this.languagesLoaded = true
            console.log('LOADED: Languages', languageList)
        })
    }

    fetchPokemonData = async (): Promise<void> => {
        await Dex.getPokemonsList().then(
            async (allPokemon: Type.NamedUrlList) => {
                // console.log("allPokemon", allPokemon);
                /*
      await Promise.all(
        allPokemon.results.map((poke) => {
          return Dex.getPokemonSpeciesByName(poke.name);
        })
      ).then((speciesData) => {
        console.log("SPECIES?", speciesData);
        return;
      });
      */
                // setPokemonData(allPokemon.results as Type.NamedUrl[]);
            }
        )
    }

    fetchNatureData = async (): Promise<void> => {
        let loadingNatures: Type.NatureInfo[] = []
        const loadingNaturesMap = await Dex.getNaturesList().then(
            (natureUrlList: Type.NamedUrlList) => {
                return (
                    Promise.all(
                        natureUrlList.results.map((natureData) => {
                            return Dex.getNatureByName(natureData.name)
                        })
                    ) as Promise<Type.NatureInfo[]>
                ).then((natureData) => {
                    const sortedNatures = Utils.sortNatures(natureData)
                    loadingNatures = sortedNatures
                    return new Map(
                        sortedNatures.map((obj) => [
                            obj.name as string,
                            obj as Type.NatureInfo,
                        ])
                    )
                })
            }
        )
        runInAction(() => {
            this.AllNatures = loadingNatures
            this.AllNaturesMap = loadingNaturesMap
            this.naturesLoaded = true
            console.log('LOADED: Natures')
        })
    }

    fetchAbilityData = async (): Promise<void> => {
        let loadingAbilities: Type.Ability[] = []
        const loadingAbilitiesMap = await Dex.getAbilitiesList().then(
            (abilityUrlList: Type.NamedUrlList) => {
                return (
                    Promise.all(
                        abilityUrlList.results.map((abilityData) => {
                            return Dex.getAbilityByName(abilityData.name)
                        })
                    ) as Promise<Type.Ability[]>
                ).then((abilityData) => {
                    loadingAbilities = abilityData
                    return new Map(
                        abilityData.map((obj) => [
                            obj.name as string,
                            obj as Type.Ability,
                        ])
                    )
                })
            }
        )
        runInAction(() => {
            this.AllAbilities = loadingAbilities
            this.AllAbilitiesMap = loadingAbilitiesMap
            this.abilitiesLoaded = true
            console.log('LOADED: Abilities')
        })
    }

    fetchItemData = async (): Promise<void> => {
        let loadingItems: Type.Item[] = []
        const loadingItemsMap = await (
            Promise.all(
                Object.keys(Type.HoldItemCategory)
                    .filter((key) => !key.match(/\d+/))
                    .map((holdItemCat): Promise<any> => {
                        return Dex.getItemCategoryByName(holdItemCat as string)
                    })
            ) as Promise<Type.ItemCategory[]>
        ).then(async (holdItemCatData): Promise<Map<string, Type.Item>> => {
            let itemUrls: Type.NamedUrl[] = []
            for (let i = 0; i < holdItemCatData.length; i++) {
                itemUrls = [...itemUrls, ...holdItemCatData[i].items]
            }
            return await Promise.all(
                itemUrls.map((itemUrl): Promise<any> => {
                    return Dex.getItemByName(itemUrl.name)
                })
            ).then((itemData: Type.Item[]) => {
                loadingItems = itemData
                return new Map(
                    itemData.map((obj) => [
                        obj.name as string,
                        obj as Type.Item,
                    ])
                )
            })
        })
        runInAction(() => {
            this.AllItems = loadingItems
            this.AllItemsMap = loadingItemsMap
            this.itemsLoaded = true
            console.log('LOADED: Items')
        })
    }

    fetchMoveData = async (): Promise<void> => {
        let loadingMoves: Type.Move[] = []
        const loadingMovesMap = await Dex.getMovesList().then(
            (movesUrlList: Type.NamedUrlList) => {
                // For some reason the api returns a few moves twice- so we need to remove duplicate IDs for React
                // No idea how to get these specific objects out... luckily they follow a unique name scheme
                const uniqueArray = movesUrlList.results.filter(
                    (move) =>
                        !['--physical', '--special'].some((problemWord) =>
                            move.name.includes(problemWord)
                        )
                )
                return (
                    Promise.all(
                        uniqueArray.map((moveData) => {
                            return Dex.getMoveByName(moveData.name)
                        })
                    ) as Promise<Type.Move[]>
                ).then((moveData) => {
                    loadingMoves = moveData
                    return new Map(
                        moveData.map((obj) => [
                            obj.name as string,
                            obj as Type.Move,
                        ])
                    )
                })
            }
        )
        runInAction(() => {
            this.AllMoves = loadingMoves
            this.AllMovesMap = loadingMovesMap
            this.movesLoaded = true
            console.log('LOADED: Moves')
        })
    }
}
/* Store End */

/* Store Helpers */
// const DataStoreContext = React.createContext(new DataStore());

/*
export const PokemonProvider = ({ children, store }: any) => {
  return (
    <DataStoreContext.Provider value={store}>
      {children}
    </DataStoreContext.Provider>
  );
};
*/

/* Hook to use store in any functional component */
// export const useStore = () => React.useContext(DataStoreContext);

/* HOC to inject store to any functional or class component */
/*
export const withStore = (Component: any) => (props: any) => {
  return <Component {...props} store={useStore()} />;
};
*/
