export const ApiGQLEndpoint = "https://beta.pokeapi.co/graphql/v1beta";

export enum Languages {
  en = "en",
  ja = "ja",
  ko = "ko",
  es = "es",
  fr = "fr",
  de = "de",
  it = "it",
  "ja-Hrkt" = "ja-Hrkt",
  // zh = "zh-Hant",
  // roomaji = "roomaji",
  // "zh-Hant" = "zh-Hant",
}

export enum HoldItemCategory {
  "choice",
  "held-items",
  "bad-held-items",
  "type-enhancement",
  "type-protection",
  "medicine",
  "picky-healing",
  "in-a-pinch",
  "other",
  "mega-stones",
  "species-specific",
  "plates",
}

// For the future if we limit moves to a game
export enum SupportedVersionGroups {
  "heartgold-soulsilver",
  "black-2-white-2",
  "x-y",
  "omega-ruby-alpha-sapphire",
  "ultra-sun-ultra-moon",
  "lets-go-pikachu-lets-go-eevee",
  "sword-shield",
  "brilliant-diamond-and-shining-pearl",
  "legends-arceus",
  "scarlet-violet",
}

export enum SupportedPokedexes {
  "national",
  "kanto",
  "original-johto",
  "hoenn",
  "original-sinnoh",
  // "extended-sinnoh",
  // "updated-johto",
  "original-unova",
  // "updated-unova",
  "kalos-central",
  "kalos-coastal",
  "kalos-mountain",
  // "updated-hoenn",
  "original-alola",
  // "original-melemele",
  // "original-akala",
  // "original-ulaula",
  // "original-poni",
  // "updated-alola",
  // "updated-melemele",
  // "updated-akala",
  // "updated-ulaula",
  // "updated-poni",
  // "letsgo-kanto",
  "galar",
  "isle-of-armor",
  "crown-tundra",
  "hisui",
  "paldea",
  // "kitakami",
  // "indigo-disk",
}

export const customOptions = {
  protocol: "https",
  // hostName: "localhost:443",
  versionPath: "/api/v2/",
  cache: true,
  timeout: 15 * 1000, // 15 seconds
  cacheImages: true,
  type: "text/javascript",
};

export enum Natures {
  Hardy = "hardy",
  Lonely = "lonely",
  Brave = "brave",
  Adamant = "adamant",
  Naughty = "naughty",
  Bold = "bold",
  Docile = "docile",
  Relaxed = "relaxed",
  Impish = "impish",
  Lax = "lax",
  Timid = "timid",
  Hasty = "hasty",
  Serious = "serious",
  Jolly = "jolly",
  Naive = "naive",
  Modest = "modest",
  Mild = "mild",
  Quiet = "quiet",
  Bashful = "bashful",
  Rash = "rash",
  Calm = "calm",
  Gentle = "gentle",
  Sassy = "sassy",
  Careful = "careful",
  Quirky = "quirky",
}

export enum StatNames {
  Hp = "hp",
  Attack = "attack",
  Defense = "defense",
  SpecialAttack = "special-attack",
  SpecialDefense = "special-defense",
  Speed = "speed",
}

export enum SpeciesColor {
  black = "black",
  blue = "blue",
  brown = "brown",
  gray = "gray",
  green = "green",
  pink = "pink",
  purple = "purple",
  red = "red",
  white = "white",
  yellow = "yellow",
}

export enum TypeColor {}

export enum Generation {
  "generation-i" = "generation-i",
  "generation-ii" = "generation-ii",
  "generation-iii" = "generation-iii",
  "generation-iv" = "generation-iv",
  "generation-v" = "generation-v",
  "generation-vi" = "generation-vi",
  "generation-vii" = "generation-vii",
  "generation-viii" = "generation-viii",
  "generation-ix" = "generation-ix",
}

export const totalEVs = 510;
export const maxEV = 255;
export const minEV = 0;
export const maxIV = 31;
export const minIV = 0;

export type teamIndexRange = 0 | 1 | 2 | 3 | 4 | 5;
export type moveIndexRange = 0 | 1 | 2 | 3;

export enum UpdatableSection {
  Pokemon = "pokemon",
  Canvas = "canvas",
  Item = "item",
  Ability = "ability",
  Nature = "nature",
  Move = "move",
  Stat = "stat",
}

export enum UpdatableSubSection {
  PokemonVariety = "pokemonvariety",
  ShinyState = "shinystate",
  GenderState = "genderstate",
  DirectionState = "directionstate",
  IVState = "ivstate",
  EVState = "evstate",
}

export interface NamedUrl {
  name: string;
  url: Url;
}

export interface NamelessUrl {
  url: Url;
}

export type Url = string;

export interface translatedOption {
  displayName: string;
  keyName: string;
}

export interface BuildDetails {
  moves?: (Move | undefined)[];
  nature?: NatureInfo;
  heldItem?: Item;
  evData?: EVs;
  ivData?: IVs;
  ability?: Ability;
}

export interface EVs {
  HP: number;
  Atk: number;
  Def: number;
  SpAtk: number;
  SpDef: number;
  Spe: number;
}

export interface IVs extends EVs {}

export interface Pokemon extends PokemonDexData, PokemonGameData {
  currentDisplayImage?: Url;
  currentSpriteView?: SpriteView;
  currentVarietyName?: string;
  currentAbilityIdentifier?: string; // If ability data hasn't been loaded yet
  boxSprite?: Url;
  preferShiny: boolean;
  preferBackView: boolean;
  preferFemale: boolean;
  originalId?: number;
  buildDetails?: BuildDetails;
  varietyData?: PokemonVariety[];
}

export interface PokemonVariety extends PokemonGameData {
  displayName?: string;
}

export interface PokemonDexDataGQL {
  name: string;
}

export interface PokemonDexData {
  base_happiness: number;
  capture_rate: number;
  color: NamedUrl;
  egg_groups: NamedUrl[];
  evolution_chain: NamelessUrl;
  evolves_from_species: NamedUrl | null;
  flavor_text_entries: FlavorTextOptionVersion[];
  form_descriptions: any[]; // Return to this when I know what this is
  forms_switchable: boolean;
  gender_rate: number;
  genera: Genus[];
  generation: NamedUrl;
  growth_rate: NamedUrl;
  habitat: NamedUrl;
  has_gender_differences: boolean;
  hatch_counter: number;
  id: number;
  is_baby: boolean;
  is_legendary: boolean;
  is_mythical: boolean;
  name: string;
  names: NameOption[];
  order: number;
  pal_park_encounters: PalParkEncounterOption[];
  pokedex_numbers: PokedexOption[];
  shape: NamedUrl;
  varieties: VarietyOption[];
}

export interface PokemonGameData {
  abilities: AbilityOption[];
  base_experience: number;
  forms: NamedUrl[];
  game_indices: GameIndex[];
  height: number;
  held_items: HeldItemOption[]; // Return to definition
  id: number;
  is_default: boolean;
  location_area_encounters: Url;
  moves: MoveOption[];
  name: string;
  order: number;
  past_types: any[]; // Don't know what type this should be yet
  species: NamedUrl;
  sprites: Sprite;
  stats: StatData[];
  types: SlotType[];
  weight: number;
}

export interface AbilityOption {
  ability: NamedUrl;
  is_hidden: boolean;
  slot: number;
}

export interface EffectChanges {
  effect_entries: EffectEntry[];
  version_group: NamedUrl;
}

export interface PokemonAbilityRelation {
  is_hidden: boolean;
  pokemon: NamedUrl;
  slot: number;
}

export interface Ability {
  effect_changes: EffectChanges[];
  effect_entries: EffectEntry[];
  flavor_text_entries: FlavorTextOptionVersionGroup[];
  generation: NamedUrl;
  id: number;
  is_main_series: boolean;
  name: string;
  names: NameOption[];
  pokemon: PokemonAbilityRelation[];
}

export interface GameIndex {
  game_index: number;
  version: NamedUrl;
}

export interface MoveOption {
  move: NamedUrl;
  version_group_details: VersionGroupOption[];
}

export interface Move {
  accuracy: number;
  contest_combos: any; // ??
  contest_effect: any; // ??
  contest_type: any; // ??
  damage_class: NamedUrl;
  effect_chance: any; // ??
  effect_changes: any; // ??
  effect_entries: any; // ??
  flavor_text_entries: any; // ??
  generation: NamedUrl;
  id: number;
  learned_by_pokemon: NamedUrl[];
  machines: any; // ??
  meta: any; // ??
  name: string;
  names: NameOption[];
  past_values: any; // ??
  power: number;
  pp: number;
  priority: number;
  stat_changes: any; // ??
  super_contest_effect: any; // ??
  target: NamedUrl;
  type: NamedUrl;
}

export interface HeldItemOption {
  item: NamedUrl;
  version_details: VersionOption[];
}

export interface HeldItem {}

export interface SpriteVersionOptions {
  "generation-i"?: Gen1Sprites;
  "generation-ii"?: Gen2Sprites;
  "generation-iii"?: Gen3Sprites;
  "generation-iv"?: Gen4Sprites;
  "generation-v"?: Gen5Sprites;
  "generation-vi"?: Gen6Sprites;
  "generation-vii"?: Gen7Sprites;
  "generation-viii"?: Gen8Sprites;
}

export interface VersionOption {
  rarity: number;
  version: NamedUrl;
}

export interface Version {} // Ping Version endpoint and add here

export interface VersionGroupOption {
  level_learned_at: number;
  move_learn_method: NamedUrl;
  version_group: NamedUrl;
}

export interface VersionGroup {
  generation: NamedUrl;
  id: number;
  move_learn_methods: any[]; // ??
  name: string;
  order: number;
  pokedexes: NamedUrl[];
  regions: NamedUrl[];
  versions: NamedUrl[];
}

export interface MoveLearnMethodOption {}

export interface MoveLearnMethod {}

export interface StatData {
  base_stat: number;
  effort: number;
  stat: NamedUrl;
}

export interface Stat {} // Ping Stat endpoint and add here

export interface SlotType {
  slot: number;
  type: NamedUrl;
}

export interface NamedUrlList {
  count: number;
  next?: any;
  previous?: any;
  results: NamedUrl[];
}

export interface Type {} // Ping Type endpoint and add here

export interface Sprite extends SpriteDetails {
  other?: OtherSpriteDetails;
  versions?: SpriteVersionOptions;
}

export type SpriteDetails = {
  [key in SpriteView]: Url | null;
};

export type SpriteView =
  | "back_default"
  | "back_female"
  | "back_shiny"
  | "back_shiny_female"
  | "front_default"
  | "front_female"
  | "front_shiny"
  | "front_shiny_female"
  | "back_gray"
  | "back_transparent"
  | "back_shiny_transparent"
  | "front_gray"
  | "front_transparent"
  | "front_shiny_transparent";

export interface AnimatedSpriteDetails extends SpriteDetails {
  animated: SpriteDetails;
}

export interface OtherSpriteDetails {
  dream_world?: SpriteDetails;
  home?: SpriteDetails;
  "official-artwork": SpriteDetails;
}

export interface Gen1Sprites {
  "red-blue"?: SpriteDetails;
  yellow?: SpriteDetails;
}

export interface Gen2Sprites {
  crystal?: SpriteDetails;
  gold?: SpriteDetails;
  silver?: SpriteDetails;
}

export interface Gen3Sprites {
  emerald?: SpriteDetails;
  "firered-leafgreen"?: SpriteDetails;
  "ruby-sapphire"?: SpriteDetails;
}

export interface Gen4Sprites {
  "diamond-pearl"?: SpriteDetails;
  "heartgold-soulsilver"?: SpriteDetails;
  platinum?: SpriteDetails;
}

export interface Gen5Sprites {
  "black-white"?: AnimatedSpriteDetails;
}

export interface Gen6Sprites {
  "omegaruby-alphasapphire"?: SpriteDetails;
  "x-y"?: SpriteDetails;
}

export interface Gen7Sprites {
  icons?: SpriteDetails;
  "ultra-sun-ultra-moon"?: SpriteDetails;
}

export interface Gen8Sprites {
  icons?: SpriteDetails;
}

export interface FlavorTextOption {
  flavor_text: string;
  language: NamedUrl;
}

export interface FlavorTextOptionVersion extends FlavorTextOption {
  version: NamedUrl;
}

export interface FlavorTextOptionVersionGroup extends FlavorTextOption {
  version_group: NamedUrl;
}

export interface Genus {
  genus: string;
  language: NamedUrl;
}

export interface NameOption {
  language: NamedUrl;
  name: string;
}

export interface PalParkEncounterOption {
  area: NamedUrl;
  base_score: number;
  rate: number;
}

export interface PokedexOption {
  entry_number: number;
  pokedex: NamedUrl;
}

export interface VarietyOption {
  is_default: boolean;
  pokemon: NamedUrl;
}

export interface NatureInfo {
  decreased_stat: NamedUrl;
  hates_flavor: NamedUrl;
  id: number;
  increased_stat: NamedUrl;
  likes_flavor: NamedUrl;
  move_battle_style_preferences: BattleStylePreferences[];
  name: string;
  names: NameOption[];
  pokeathlon_stat_changes: PokeathlonStatChanges[];
}

export interface BattleStylePreferences {
  high_hp_preference: number;
  low_hp_preference: number;
  move_battle_style: NamedUrl;
}

export interface PokeathlonStatChanges {
  max_change: number;
  pokeathlon_stat: NamedUrl;
}

export interface Description {
  description: string;
  language: NamedUrl;
}

export interface PokedexEntry {
  entry_number: number;
  pokemon_species: NamedUrl;
}

export interface PokedexData {
  description: Description[];
  id: number;
  is_main_series: boolean;
  name: string;
  names: NameOption[];
  pokemon_entries: PokedexEntry[];
  region?: NamedUrl;
  version_groups: NamedUrl[];
}

export interface Name {
  name: string;
}

export interface LocalizedGQL {
  displayName: string;
  lang?: Name;
}

export interface LanguageGQL {
  code?: string;
  localized?: LocalizedGQL[];
}

export interface LanguagesGQL {
  languages: LanguageGQL[];
}

export interface PokedexGQL {
  name: string;
  localized: LocalizedGQL[];
}

export interface PokedexesGQL {
  pokedexes: PokedexGQL[];
}

export interface EffectEntry {
  effect: string;
  language: NamedUrl;
  short_effect: string;
}

export interface FlavorTextEntry {
  language: NamedUrl;
  text: string;
  version_group: NamedUrl;
}

export interface GameIndex {
  game_index: number;
  generation: NamedUrl;
}

export interface VersionDetails {
  rarity: number;
  version: NamedUrl;
}

export interface HeldByPokemon {
  pokemon: NamedUrl;
  version_details: VersionDetails[];
}

export interface ItemSprite {
  default: string;
}

export interface Machine {
  machine: NamelessUrl;
  version_group: NamedUrl;
}

export interface ItemCategory {
  id: number;
  items: NamedUrl[];
  name: string;
  names: NameOption[];
  pocket: NamedUrl;
}

export interface Item {
  attributes: NamedUrl[];
  baby_trigger_for: NamelessUrl | null;
  category: NamedUrl;
  cost: number;
  effect_entries: EffectEntry[];
  flavor_text_entries: FlavorTextEntry[];
  fling_effect: NamedUrl | null;
  fling_power: number | null;
  game_indices: GameIndex[];
  held_by_pokemon: HeldByPokemon[];
  id: number;
  machines: Machine[];
  name: string;
  names: NameOption[];
  sprites: ItemSprite;
}
