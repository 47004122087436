import React, { ReactElement, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { NavOption, allNavOptions } from './navigation/navigationOptions'

export type MainWindowProps = {
    children: JSX.Element[]
}

const MainWindow = (props: MainWindowProps): ReactElement => {
    const { children } = props

    const location = useLocation()

    const getCurrentNavPage = useCallback((): NavOption | undefined => {
        return allNavOptions.find((opt) => {
            return opt.urlLink === location.pathname
        })
    }, [location.pathname])

    return (
        <div className={`main-window ${getCurrentNavPage()?.key || ''}`}>
            {children}
        </div>
    )
}

export default MainWindow
