import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './components/App'

import './stylesheets/_export.scss'

/*
Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    cookieStorage: {
      domain: config.cognito.COOKIE_STORAGE.DOMAIN,
      secure: config.cognito.COOKIE_STORAGE.SECURE,
      path: config.cognito.COOKIE_STORAGE.PATH,
      expires: config.cognito.COOKIE_STORAGE.EXPIRES
    }
  },
  Storage: {
    AWSS3: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET
    }
  }
})

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: config.cognito.IDENTITY_POOL_ID
})
*/

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App />)
