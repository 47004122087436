import * as tsDefs from '../../tsDefs'

export type NavOption = {
    key: string
    urlLink: string
}

export const navHome: NavOption = { key: 'Home', urlLink: '/' }
export const navProjects: NavOption = {
    key: 'Projects',
    urlLink: '/projects',
}
export const projPhasmophobia: NavOption = {
    key: tsDefs.ProjectKey.phasmophobiaGhostHuntersIndex,
    urlLink: `${navProjects.urlLink}/phasmophobia`,
}
export const projForewarned: NavOption = {
    key: tsDefs.ProjectKey.forewarnedMejaiBanishersIndex,
    urlLink: `${navProjects.urlLink}/forewarned`,
}
export const projTypeTool: NavOption = {
    key: tsDefs.ProjectKey.pokemonTypeTool,
    urlLink: `${navProjects.urlLink}/pokemon-type-tool`,
}
export const projTeamBuilder: NavOption = {
    key: tsDefs.ProjectKey.pokemonTeamBuilder,
    urlLink: `${navProjects.urlLink}/pokemon-team-builder`,
}

export const mainNavOptions: NavOption[] = [navHome, navProjects]
export const allNavOptions: NavOption[] = [
    ...mainNavOptions,
    projPhasmophobia,
    projForewarned,
    projTypeTool,
    projTeamBuilder,
]
