import React, { useMemo } from 'react'
import * as tsDefs from '../../tsDefs'

export type ProjectMetadata = {
    key: tsDefs.ProjectKey
    url: string
    name: string
    release: string
    description: string[]
    frontSrc: string
    frontAlt: string
    backSrc: string
    backAlt: string
    demoVideo: string
}

export type ProjectInfoBoxProps = {
    metadata: ProjectMetadata
    boxWidth: number
    currentBreakpoint: tsDefs.ScreenBreakpoints
}

const ProjectInfoBox = (props: ProjectInfoBoxProps) => {
    const { metadata, boxWidth, currentBreakpoint } = props
    const {
        key,
        url,
        name,
        release,
        description,
        frontSrc,
        frontAlt,
        backSrc,
        backAlt,
        demoVideo,
    } = metadata

    const videoContent = useMemo(() => {
        let padding = 6
        if (currentBreakpoint === tsDefs.ScreenBreakpoints.smalldesktop) {
            padding = 9
        }
        if (currentBreakpoint === tsDefs.ScreenBreakpoints.desktop) {
            padding = 12
        }
        const doublePadding = padding * 2
        const videoRatio = 1.77
        return (
            <div
                className='extrasVideoWrapper'
                style={{
                    width: boxWidth - doublePadding,
                    height: (boxWidth - doublePadding) / videoRatio,
                }}
            >
                <video
                    className='extrasVideo'
                    autoPlay={true}
                    loop={true}
                    style={{
                        width: boxWidth - doublePadding,
                        height: (boxWidth - doublePadding) / videoRatio,
                    }}
                >
                    <source src={demoVideo} type='video/mp4' />
                </video>
            </div>
        )
    }, [boxWidth, currentBreakpoint])

    return (
        <div className='flip-box'>
            <div className='flip-box-inner'>
                <div
                    className='flip-box-front'
                    style={{
                        backgroundImage: `url(${frontSrc})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                ></div>
                <div className='flip-box-back'>
                    {videoContent}
                    <div className='extrasDetailsContainer'>
                        <div className='extrasDetailsName'>{name}</div>
                        <div className='extrasDetailsRelease'>
                            Release: {release}
                        </div>
                        <div className='extrasDetailsDescription'>
                            {description.map((paragraph, index) => {
                                return (
                                    <span
                                        key={`Extra-${key}-Paragraph${index}`}
                                    >
                                        {paragraph}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectInfoBox
