import request, { gql } from "graphql-request";
import * as Type from "../../extras/pokemonTeamBuilder/TeamBuilderTypes";

function getLanguagesQuery(
  localLanguage: Type.Languages = Type.Languages.en
): string {
  const query = gql`
      query getLanguages {
        languages: pokemon_v2_language {
          code: name
          localized: pokemon_v2_languagenames(where: {pokemonV2LanguageByLocalLanguageId: {name: {_eq: "${localLanguage}"}}}) {
            displayName: name
          }
        }
      }
    `;
  return query;
}

async function getLanguages(
  localLanguage: Type.Languages = Type.Languages.en
): Promise<Type.LanguagesGQL> {
  return request(Type.ApiGQLEndpoint, getLanguagesQuery(localLanguage)).then(
    (results) => {
      const permittedLanguages = Object.values(Type.Languages);
      const filteredOptions = (results as Type.LanguagesGQL).languages.filter(
        (option) => permittedLanguages.includes(option?.code as Type.Languages)
      );
      return { languages: filteredOptions } as Type.LanguagesGQL;
    }
  );
}

export { getLanguages };
