import request, { gql } from "graphql-request";
import * as Type from "../../extras/pokemonTeamBuilder/TeamBuilderTypes";

function getPokedexesQuery(
  localLanguage: Type.Languages = Type.Languages.en
): string {
  const query = gql`
    query getPokedexes {
        pokedexes: pokemon_v2_pokedex(where: {is_main_series: {_eq: true}}) {
          name
          localized: pokemon_v2_pokedexnames(where: {pokemon_v2_language: {name: {_regex: "^${localLanguage}|en$"}}}) {
            displayName: name
            lang: pokemon_v2_language {
              name
            }
          }
        }
      }
  `;
  return query;
}

async function getPokedexes(
  localLanguage: Type.Languages = Type.Languages.en
): Promise<Type.PokedexesGQL> {
  return request(Type.ApiGQLEndpoint, getPokedexesQuery(localLanguage)).then(
    (results) => {
      const permittedPokedexes = Object.values(Type.SupportedPokedexes);
      const filteredOptions = (results as Type.PokedexesGQL).pokedexes.filter(
        (option) => permittedPokedexes.includes(option?.name as string)
      );
      return { pokedexes: filteredOptions } as Type.PokedexesGQL;
    }
  );
}

function getPokedexDisplayName(
  dex: Type.PokedexGQL,
  localLanguage: Type.Languages = Type.Languages.en
): string {
  // Will fall back to english if the current language can't be found.
  let display = "";
  if (dex.localized) {
    display =
      dex.localized.find((option) => option.lang?.name === localLanguage)
        ?.displayName || "";
    if (!display) {
      display =
        dex.localized.find((option) => option.lang?.name === Type.Languages.en)
          ?.displayName || "";
    }
  }
  return display ? display : dex.name || "Pokedex Not Found";
}

export { getPokedexes, getPokedexDisplayName };
