import * as tsDefs from '../tsDefs'

export function getScreenBreakpoint(screenWidth: number): tsDefs.ScreenBreakpoints {
    let currentBreakpoint = tsDefs.ScreenBreakpoints.desktop
    if (screenWidth <= 1600) {
        currentBreakpoint = tsDefs.ScreenBreakpoints.smalldesktop
    }
    if (screenWidth <= 1280) {
        currentBreakpoint = tsDefs.ScreenBreakpoints.laptop
    }
    if (screenWidth <= 1024) {
        currentBreakpoint = tsDefs.ScreenBreakpoints.smalllaptop
    }
    return currentBreakpoint
}
