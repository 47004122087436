import React, { ReactElement, useState } from 'react'
import { Dialog, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export type UnderConstructionProps = {
    name: string
}

const UnderConstruction = (props: UnderConstructionProps): ReactElement => {
    const [showDialog, setShowDialog] = useState(true)

    return (
        <Dialog className='construction-modal-wrapper' open={showDialog}>
            <div className='construction-modal'>
                <IconButton
                    className='close-button'
                    onClick={() => setShowDialog(false)}
                    sx={{
                        outline: 0,
                        border: 0,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <div className='header'>Under Construction</div>
                <div className='body-text'>
                    Apologies for the look! This website is being remodeled and
                    will look rough around the edges.
                </div>
            </div>
        </Dialog>
    )
}

export default UnderConstruction
