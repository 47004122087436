import React, {
    ReactElement,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react'
import ProjectInfoBox, { ProjectMetadata } from './extras/projectInfoBox'
import * as tsDefs from '../tsDefs'
import { getScreenBreakpoint } from 'utilities/mainUtils'
import { useNavigate } from 'react-router-dom'
import { useStores } from './useStores'

const Projects = (): ReactElement => {
    const navigate = useNavigate()
    const { globalStore } = useStores()
    const projectData: ProjectMetadata[] = [
        {
            key: tsDefs.ProjectKey.phasmophobiaGhostHuntersIndex,
            url: '/projects/phasmophobia',
            name: "Phasmophobia Ghost Hunter's Index",
            release: 'November 22, 2021',
            description: [
                "This web tool is made for the ghost hunting horror game 'Phasmophobia' and will help you and your friends determine the ghost's identity.",
                "Select or exclude evidence and use the provided information to learn more about each ghost's temperament.",
            ],
            frontSrc:
                '../images/extrasInfoGraphics/phasmophobiaGhostHuntersIndex.jpg',
            frontAlt: "Phasmophobia Ghost Hunter's Index",
            backSrc: '',
            backAlt: '',
            demoVideo:
                '../videos/extrasInfoVideos/PhasmophobiaGhostHuntersIndex.mp4',
        },
        {
            key: tsDefs.ProjectKey.forewarnedMejaiBanishersIndex,
            url: '/projects/forewarned',
            name: "Forewarned Mejai Banisher's Index",
            release: 'N/A',
            description: [
                'Development in Progress',
                "This web tool is made for the egyptian tomb-scavenging horror game 'Forewarned' and will help you and your friends determine the mejai's identity.",
                "Select or exclude evidence and use the provided information to successfully evade the mejai's hunt.",
            ],
            frontSrc:
                '../images/extrasInfoGraphics/forewarnedMejaiHuntersIndex.png',
            frontAlt: "Forewarned Mejai Hunter's Index",
            backSrc: '',
            backAlt: '',
            demoVideo: '',
        },
        {
            key: tsDefs.ProjectKey.pokemonTypeTool,
            url: '/projects/pokemon-type-tool',
            name: 'Pokemon Type Tool',
            release: 'N/A',
            description: [
                'Development in Progress',
                'This is an interactive tier list of every possible Pokemon type combination. Tiers are determined with a numeric value based on how many resistances the type combination has.',
                'Hover over each combination to see a display of every Pokemon with those types.',
            ],
            frontSrc: '../images/extrasInfoGraphics/pokemonTypeTool.png',
            frontAlt: 'Pokemon Type Tool',
            backSrc: '',
            backAlt: '',
            demoVideo: '',
        },
        {
            key: tsDefs.ProjectKey.pokemonTeamBuilder,
            url: '/projects/pokemon-team-builder',
            name: 'Pokemon Team Builder',
            release: 'N/A',
            description: ['Development in Progress'],
            frontSrc: '../images/extrasInfoGraphics/pokemonTeamBuilder.png',
            frontAlt: 'Pokemon Team Builder',
            backSrc: '',
            backAlt: '',
            demoVideo: '',
        },
    ]

    const [boxWidth, setBoxWidth] = useState(0)
    const [boxHeight, setBoxHeight] = useState(0)
    const [currentBreakpoint, setCurrentBreakpoint] = useState(
        getScreenBreakpoint(screen.width)
    )

    useEffect(() => {
        const widthObserver = new ResizeObserver((entries) => {
            const minWidth = 220
            const maxWidth = 290
            const screenSize = entries[0].contentRect.width
            const numberOnScreen = 4
            const width = Math.min(
                maxWidth,
                Math.max(minWidth, screenSize / numberOnScreen)
            )
            setCurrentBreakpoint(getScreenBreakpoint(screenSize))
            setBoxWidth(width)
            setBoxHeight((width / 5) * 7)
        })
        widthObserver.observe(document.body)

        return () => {
            widthObserver.disconnect()
        }
    })

    return (
        <div id='project-container'>
            {projectData.map((project, index) => {
                const { key, url } = project
                return (
                    <button
                        className='project-button'
                        onClick={() => {
                            globalStore.setCurrentOpenedProject(key)
                            navigate(url)
                        }}
                        key={`extraAppLink-${key}`}
                        style={{ width: boxWidth, height: boxHeight }}
                    >
                        <ProjectInfoBox
                            metadata={project}
                            boxWidth={boxWidth}
                            currentBreakpoint={currentBreakpoint}
                        />
                    </button>
                )
            })}
        </div>
    )
}

export default Projects
