import React, { useState, useEffect, Suspense, useCallback } from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
    useLocation,
} from 'react-router-dom'
import Navigation from './navigation'

// https://www.buymeacoffee.com/evankahn

import '../stylesheets/styles.scss'
import HomePage from './homePage'
import ResumePage from './resumePage'
import ContactPage from './contactPage'
import Projects from './projects'
import { useStores } from './useStores'
import { observer } from 'mobx-react'
import MainWindow from './mainWindow'
import * as navOpt from './navigation/navigationOptions'
import UnderConstruction from './underconstruction'

// EXTRAS
const GhostHuntersIndex = React.lazy(
    () => import('./extras/phasmophobia/GhostHuntersIndex')
)
const MejaiBanishersIndex = React.lazy(
    () => import('./extras/forewarned/MejaiBanishersIndex')
)
const PokemonTypeTool = React.lazy(
    () => import('./extras/pokemonTypeTool/PokemonTypeTool')
)
const PokemonTeamBuilder = React.lazy(
    () => import('./extras/pokemonTeamBuilder/TeamBuilder')
)

const App = observer(() => {
    const { globalStore } = useStores()
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        onLoad()
    }, [])

    async function onLoad() {
        // Do async loading necessities here
        setIsLoaded(true)
    }

    return (
        isLoaded && (
            <BrowserRouter>
                <Routes>
                    <Route
                        element={
                            <MainWindow>
                                <Navigation options={navOpt.mainNavOptions} />
                                <Outlet />
                                <UnderConstruction name='Under Construction' />
                            </MainWindow>
                        }
                    >
                        <Route
                            path={navOpt.navHome.urlLink}
                            element={<div className='hi' />}
                        />
                        <Route
                            path={navOpt.navProjects.urlLink}
                            element={<Projects />}
                        />
                        <Route
                            path={navOpt.projPhasmophobia.urlLink}
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <GhostHuntersIndex />
                                </Suspense>
                            }
                        />
                        <Route
                            path={navOpt.projForewarned.urlLink}
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <MejaiBanishersIndex />
                                </Suspense>
                            }
                        />
                        <Route
                            path={navOpt.projTypeTool.urlLink}
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <PokemonTypeTool />
                                </Suspense>
                            }
                        />
                        <Route
                            path={navOpt.projTeamBuilder.urlLink}
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <PokemonTeamBuilder />
                                </Suspense>
                            }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        )
    )

    /*
  return (
    isLoaded && (
      <Router>
        <Route
          exact
          path={["/home", "/resume", "/contact", "/extra"]}
          component={NavBar}
        />
        <Switch>
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/resume" component={ResumePage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/extra" component={ExtraPage} />
        </Switch>
        <Switch>
          <Route
            exact
            path="/extra/phasmophobia"
            render={() => (
              <Suspense fallback={<div>Loading...</div>}>
                <GhostHuntersIndex />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/extra/forewarned"
            render={() => (
              <Suspense fallback={<div>Loading...</div>}>
                <MejaiBanishersIndex />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/extra/pokemon-type-tool"
            render={() => (
              <Suspense fallback={<div>Loading...</div>}>
                <PokemonTypeTool />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/extra/pokemon-team-builder"
            render={() => (
              <Suspense fallback={<div>Loading...</div>}>
                <PokemonTeamBuilder />
              </Suspense>
            )}
          />
        </Switch>
      </Router>
    )
  );
  */
})

export default App
