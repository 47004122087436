import React, { ReactElement } from 'react'
import GitHubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

const Socials = (): ReactElement => {
    return (
        <div id='social-links'>
            <a
                href='https://www.linkedin.com/in/evan-kahn/'
                target='_blank'
                rel='noopener noreferrer'
            >
                <LinkedInIcon />
            </a>
            <a
                href='https://github.com/VanixIsHere'
                target='_blank'
                rel='noopener noreferrer'
            >
                <GitHubIcon />
            </a>
        </div>
    )
}

export default Socials
