import {
    makeObservable,
    observable,
    action,
} from 'mobx'

import * as tsDefs from '../../tsDefs'

export class GlobalStore {
    currentOpenedProject: tsDefs.ProjectKey | undefined

    setCurrentOpenedProject = (project: tsDefs.ProjectKey) => {
      this.currentOpenedProject = project
    }

    constructor() {
        makeObservable<tsDefs.TGlobalStore>(this, {
            currentOpenedProject: observable,
            setCurrentOpenedProject: action.bound,
        })
    }

    printStore = (): void => {
        console.log(this)
    }
}
