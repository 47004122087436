import React, {
    ReactElement,
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Lottie from 'lottie-web'
import animationData from '../../images/animationData/EKWebLogoSVG.json'
import Socials from '../socials'
import { useStores } from 'components/useStores'
import { Icon } from '@mui/material'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import * as tsDefs from '../../tsDefs'
import { NavOption, allNavOptions } from './navigationOptions'

export type NavigationProps = {
    options: NavOption[]
}

const Navigation = (props: NavigationProps): ReactElement => {
    const { options } = props
    const { globalStore } = useStores()
    const [isPlaying, setIsPlaying] = useState(false)
    const logoRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const location = useLocation()

    const pathBasedClasses = useMemo((): string => {
        const currentNavOption = allNavOptions.find(
            (opt) => opt.urlLink === location.pathname
        )
        if (currentNavOption) {
            const projectKeys = Object.values(tsDefs.ProjectKey) as string[]
            console.log('we here?', currentNavOption, projectKeys)
            if (projectKeys.includes(currentNavOption.key)) {
                return 'project-url'
            }
        }
        return ''
    }, [location.pathname])

    const reloadLottie = (): void => {
        Lottie.destroy()
        if (logoRef.current) {
            Lottie.loadAnimation({
                container: logoRef.current,
                animationData: animationData,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                name: 'EKLogo',
            })
            console.log('loaded anim')
        }
    }

    const handleClick = (url: string): void => {
        navigate(url)
    }

    useEffect(() => {
        if (isPlaying) {
            Lottie.play('EKLogo')
        } else {
            reloadLottie()
        }
    }, [isPlaying])

    const playAnimation = (): void => {
        setIsPlaying(true)
    }

    /*
    const resetAnimation = (): void => {
        setIsPlaying(false)
    }
    */

    const logo: ReactElement = (
        <div
            ref={logoRef}
            id='ek-logo'
            className='logo'
            /*
            data-animation-path='./logoData/EKWebLogoSVG.json'
            data-anim-loop='true'
            data-name='ek-logo-animation'
            */
        />
    )

    const renderCurrentProjectName = useMemo((): ReactElement | null => {
        return null
        return (
            <div id='last-opened-project'>
                <KeyboardDoubleArrowRightIcon />
                <span id='last-opened-project-name'>
                    {globalStore.currentOpenedProject}
                </span>
            </div>
        )
    }, [globalStore.currentOpenedProject])

    return (
        <div id='navigation' className={pathBasedClasses}>
            <div className='nav-left'>
                <div
                    role='button'
                    onClick={playAnimation}
                    className={`logo-wrapper${
                        !isPlaying ? ' primed-animation' : ''
                    }`}
                >
                    {logo}
                </div>
            </div>
            <div className='nav-center'>
                {options.map((opt) => {
                    const shouldShowProjectName =
                        opt.key === 'Projects' &&
                        globalStore.currentOpenedProject
                    return (
                        <button
                            key={opt.key}
                            onClick={() => handleClick(opt.urlLink)}
                            className='nav-option'
                        >
                            <span className='nav-label'>{opt.key}</span>
                            {shouldShowProjectName && renderCurrentProjectName}
                            <div className='hover-underline' />
                        </button>
                    )
                })}
            </div>
            <div className='nav-right'>
                <Socials />
            </div>
        </div>
    )
}

export default Navigation
