import axios from "axios";
import * as Type from "./TeamBuilderTypes";
import React from "react";
import Flag from "react-world-flags";
import { DataStore } from "../../store/DataStore";
import { PaletteOptions, PaletteColorOptions } from "@mui/material";
import "./styleAugment";

/*
en = "en",
  ja = "ja",
  ko = "ko",
  es = "es",
  fr = "fr",
  de = "de",
  it = "it",
  zh = "zh-Hans",
*/

type GetResponse = {
  data?: any;
  status?: any;
  error?: any;
};

export const imageFolder = "../images/extras/teambuilder";
export const natureImgFolder = `${imageFolder}/natureBonuses`;
export const typeImageFolder = `${imageFolder}/types`;
export const moveTypeImageFolder = `${imageFolder}/moveTypes`;
export const moveDamageCatFolder = `${imageFolder}/moveDamageCats`;
export const typesEndpoint = "https://pokeapi.co/api/v2/type/";
export const naturesEndpoint = "https://pokeapi.co/api/v2/nature?limit=25";

export const handleRequest = async (
  store: DataStore,
  url: string
): Promise<any> => {
  const cacheData = store.getTeamBuilderData(url);
  return (
    cacheData ||
    httpRequest(url).then(async ({ data, status, error }) => {
      if (status === 200) {
        store.setTeamBuilderData(url, data);
        return data;
      } else {
        throw Error(
          `Problem while attempting to pull data for ${url}: ${status}`
        );
      }
    })
  );
};

export const httpRequest = async (
  url: string,
  errorMessage?: string
): Promise<GetResponse> => {
  try {
    const { data, status } = await axios.get<GetResponse>(url, {
      headers: {
        Accept: "application/json",
      },
    });
    return { data, status };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log("error message: ", error.message);
      return { error: error.message };
    } else {
      if (errorMessage) {
        console.log(errorMessage);
      } else {
        console.log("unexpected error: ", error);
      }
      return { error: "An unexpected error occurred" };
    }
  }
};

export const getPokemonName = (
  pokemon: Type.Pokemon | Type.PokemonDexData,
  language?: string
): string => {
  let specifiedLanguage = language;
  if (!language) {
    specifiedLanguage = "en";
  }
  const pokemonName = pokemon.names.find((nameOption) => {
    return nameOption.language.name === specifiedLanguage;
  });
  return pokemonName?.name || "";
};

export async function waitForUpdateQueue(updateQueue: Promise<any>[]) {
  await Promise.all(updateQueue);
}

export function getNameOfLanguage(
  nameList: Type.NameOption[],
  language: string
): string {
  let backupEnglishName = "";
  for (let i = 0; i < nameList.length; i++) {
    if (nameList[i].language.name === Type.Languages.en) {
      backupEnglishName = nameList[i].name;
    }
    if (nameList[i].language.name === language) {
      return nameList[i].name;
    }
  }
  return backupEnglishName;
}

// This variation is necessary to get the effect entry of Megastones for determining pokemon.
export function getEffectEntryOfLanguage(
  effectEntries: Type.EffectEntry[],
  language: string
): string {
  let backupEnglishEntry = "";
  for (let i = 0; i < effectEntries.length; i++) {
    if (effectEntries[i].language.name === Type.Languages.en) {
      backupEnglishEntry = effectEntries[i].effect;
    }
    if (effectEntries[i].language.name === language) {
      return effectEntries[i].effect;
    }
  }
  return backupEnglishEntry;
}

export async function getMultiplePokemon(
  urlList: string[],
  errorMessage?: string
): Promise<Type.PokemonVariety[]> {
  const allPokemonData: Type.PokemonVariety[] = [];
  for (const url of urlList) {
    await httpRequest(url).then(({ data, status, error }) => {
      if (status === 200) {
        const pokemonData: Type.PokemonGameData = data;
        const returnPokemon = {
          ...pokemonData,
          displayName: "",
        };
        allPokemonData.push(returnPokemon);
      } else {
        console.log(
          `${status} status code while attempting to get pokemon variety information.`
        );
      }
    });
  }
  return allPokemonData;
}

export function sortNatures(natures: Type.NatureInfo[]): Type.NatureInfo[] {
  // Insertion sort to organize natures
  const statNum = (statName: string): number => {
    if (statName === "attack") return 5;
    if (statName === "defense") return 4;
    if (statName === "special-attack") return 3;
    if (statName === "special-defense") return 2;
    if (statName === "speed") return 1;
    return 0;
  };
  const sortedList: Type.NatureInfo[] = [];
  for (let i = 0; i < natures.length; i++) {
    let sorted = false;
    if (!sortedList.length) {
      sortedList.push(natures[i]);
      continue;
    }
    const incStatNum = statNum(natures[i]?.increased_stat?.name || "");
    const decStatNum = statNum(natures[i]?.decreased_stat?.name || "");
    for (let j = 0; j < sortedList.length; j++) {
      if (incStatNum > statNum(sortedList[j]?.increased_stat?.name || "")) {
        sortedList.splice(j, 0, natures[i]);
        sorted = true;
        break;
      } else if (
        incStatNum === statNum(sortedList[j]?.increased_stat?.name || "")
      ) {
        if (decStatNum > statNum(sortedList[j]?.decreased_stat?.name || "")) {
          sortedList.splice(j, 0, natures[i]);
          sorted = true;
          break;
        }
      }
    }
    if (!sorted) {
      sortedList.push(natures[i]);
    }
  }
  return sortedList;
}

export function getFlagFromLanguage(language: string): JSX.Element | null {
  let flagCode = "";
  switch (language) {
    case Type.Languages.en:
      flagCode = "us";
      break;
    case Type.Languages.es:
      flagCode = "es";
      break;
    case Type.Languages.fr:
      flagCode = "fr";
      break;
    case Type.Languages.de:
      flagCode = "de";
      break;
    case Type.Languages.it:
      flagCode = "it";
      break;
    case Type.Languages.ja:
      flagCode = "jp";
      break;
    case Type.Languages.ko:
      flagCode = "kr";
      break;
    default:
      flagCode = "us";
      break;
  }
  return <Flag code={flagCode} />;
}

export function statNameToEVDetailName(statName: Type.StatNames): string {
  switch (statName) {
    case Type.StatNames.Hp:
      return "HP";
    case Type.StatNames.Attack:
      return "Atk";
    case Type.StatNames.Defense:
      return "Def";
    case Type.StatNames.SpecialAttack:
      return "SpAtk";
    case Type.StatNames.SpecialDefense:
      return "SpDef";
    case Type.StatNames.Speed:
      return "Spe";
  }
}

export function getSpeciesColorHex(color: string): string | null {
  switch (color) {
    case "black":
      return "black";
    case "blue":
      return "blue";
    case "brown":
      return "brown";
    case "gray":
      return "gray";
    case "green":
      return "green";
    case "pink":
      return "pink";
    case "purple":
      return "purple";
    case "red":
      return "red";
    case "white":
      return "white";
    case "yellow":
      return "yellow";
    default:
      return "tan";
  }
}

export function getTypeColorHex(color: string): string | null {
  switch (color) {
    case "normal":
      return "#A8A77A";
    case "fire":
      return "#EE8130";
    case "water":
      return "#6390F0";
    case "electric":
      return "#F7D02C";
    case "grass":
      return "#7AC74C";
    case "ice":
      return "#96D9D6";
    case "fighting":
      return "#C22E28";
    case "poison":
      return "#A33EA1";
    case "ground":
      return "#E2BF65";
    case "flying":
      return "#A98FF3";
    case "psychic":
      return "#F95587";
    case "bug":
      return "#A6B91A";
    case "rock":
      return "#B6A136";
    case "ghost":
      return "#735797";
    case "dragon":
      return "#6F35FC";
    case "dark":
      return "#705746";
    case "steel":
      return "#B7B7CE";
    case "fairy":
      return "#D685AD";
    default:
      return "";
  }
}
