export enum ScreenBreakpoints {
    desktop = 'desktop',
    smalldesktop = 'smalldesktop',
    laptop = 'laptop',
    smalllaptop = 'smalllaptop'
}

export enum ProjectKey {
    phasmophobiaGhostHuntersIndex = 'PhasmophobiaGhostHuntersIndex',
    forewarnedMejaiBanishersIndex = 'ForewarnedMejaiBanishersIndex',
    pokemonTypeTool = 'PokemonTypeTool',
    pokemonTeamBuilder = 'PokemonTeamBuilder'
}

export type TGlobalStore = {
    currentOpenedProject: ProjectKey | undefined,
    setCurrentOpenedProject: (project: ProjectKey) => void,
}
